export const environment = {
    production: false,
    apiURL: 'https://api-pre-prod.crizac.com/',
    fileBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/yeasterdownload/',
    zegoCloud: 'https://connect.crizac.com/',
    apiPHPURL: 'https://accounts.crizac.com/',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo',
    fileDownloadUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com',
    oneSignalAppId: 'bf2802d4-d1be-4bb5-b6ad-4e3b621f5057',
    chatBaseUrl: "wss://api-pre-prod.crizac.com/support-chat-notification",
    transportBaseURL: "https://api-pre-prod.crizac.com/",
    agentPortallink:'https://agent-pre-prod.crizac.com/',
    predictorApiKey:'1ad11872-0537-4406-b01e-de5c52995492',
    predictorApiUrl: 'https://eligibility-predictor.crizac.com',
    fileProfileUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/users/profiles/',
    sopURL:'https://sop-generator.crizac.com/'
};
